<template>
    <div>
        <button type="button" class="btn btnclass smallerfontsize mr-3 selected" @click="fieldData.showDateModal = true">
            Datum
        </button>
        <b-modal id="modal-date" v-model="fieldData.showDateModal" @hide="popupIsHidden" title="Datum" @shown="popupIsShown" @ok="saveDate">
            <b-form-row>
                <b-col lg="6" class="pl-2 mb-3">
                    <DropdownGroupTime :fieldData="fieldData" getter="getChannelsQuery" setter="setChannelsQuery" />
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col lg="6" class="pl-2">
                    <label :class="['label-text', fieldData.start_date.hasError ? 'error' : '']">
                        {{ fieldData.start_date.label }}
                    </label>
                    <div v-if="showSmallDateButtons" :class="['wrapsmallbtns date-presets-mini-buttons']">
                        <b-button @click="clicked1d" v-b-tooltip.hover title="1d: Sätt start- och slutdatum till senaste dagen med data" variant="primary" class="datefrombtn">1d</b-button>
                        <b-button @click="clicked1v" v-b-tooltip.hover title="1v: Sätt startdatum till en vecka från senaste dagen med data" variant="primary" class="datefrombtn">1v</b-button>
                        <b-button @click="clickedF1v" v-b-tooltip.hover title="f1v: Sätt start- och slutdatum till den senaste kompletta veckan med data" variant="primary" class="datefrombtn"
                            >f1v</b-button
                        >
                    </div>
                    <DateFromChannels
                        :fieldData="fieldData"
                        :latestDate="$store.getters.getPublishedToDate"
                        @onInputChanged="inputChanged"
                        getter="getChannelsQuery"
                        setter="setChannelsQuery"
                        parentName="channels"
                        mutationSetEnddate="setChannelsEnddate"
                        mutationSetStartdate="setChannelsStartdate"
                    />
                </b-col>
                <b-col lg="6" class="pr-2">
                    <DateToChannels
                        :fieldData="fieldData"
                        :latestDate="$store.getters.getPublishedToDate"
                        getter="getChannelsQuery"
                        setter="setChannelsQuery"
                        parentName="channels"
                        mutationSetEnddate="setChannelsEnddate"
                        mutationSetStartdate="setChannelsStartdate"
                    />
                </b-col>
            </b-form-row>
            <template #modal-footer="{ cancel, ok }">
                <b-button size="sm" variant="outline-secondary" @click="cancel()">
                    Avbryt
                </b-button>
                <b-button size="sm" variant="primary" :disabled="disableRunBtn()" @click="ok()">
                    Spara
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import DropdownGroupTime from "@/components/Shared/FormElement/Dropdown/DropdownGroupTime"
import DateFromChannels from "@/components/Shared/FormElement/DatePicker/DateFromChannels"
import DateToChannels from "@/components/Shared/FormElement/DatePicker/DateToChannels"
import dayjs from "dayjs"
import propsDate from "@/mixins/inputPropsDate"
import * as Mutations from "@/store/modules/channels/mutation-types"

export default {
    name: "DateModalChannels",
    mixins: [propsDate],
    components: {
        DropdownGroupTime,
        DateFromChannels,
        DateToChannels,
    },
    props: {
        fieldData: {
            type: Object,
            required: true,
        },
    },
    async mounted() {
        await this.loadGlobalConfigFromFile()
    },
    computed: {
        showSmallDateButtons() {
            let selectedGroupTimeId = this.fieldData.grouptime.tempGroupTime.id
            let showSmallDateButtons = selectedGroupTimeId === "day"
            return showSmallDateButtons
        },
    },
    watch: {
        async "fieldData.start_date.tempStartDate"() {
            let startDate = this.fieldData.start_date.tempStartDate
            let endDate = this.fieldData.end_date.tempEndDate
            if (!this.isValidDate(endDate) || !this.isValidDate(startDate)) {
                return
            }
            if (startDate > endDate) {
                this.fieldData.end_date.tempEndDate = startDate
            }
        },
        async "fieldData.end_date.tempEndDate"() {
            let startDate = this.fieldData.start_date.tempStartDate
            let endDate = this.fieldData.end_date.tempEndDate

            if (!this.isValidDate(endDate) || !this.isValidDate(startDate)) {
                return
            }
            if (startDate > endDate && endDate) {
                this.fieldData.start_date.tempStartDate = endDate
            }
        },
    },
    methods: {
        disableRunBtn() {
            let disableRunBtn = false
            if (this.fieldData.start_date.hasError || this.fieldData.end_date.hasError) disableRunBtn = true
            return disableRunBtn
        },
        inputChanged(hasError, identifier) {
            this.$emit("onInputChanged", hasError, identifier)
        },
        isValidDate(date) {
            return dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD") === date
        },
        clicked1d() {
            this.fieldData.start_date.tempStartDate = this.latestDate
            this.fieldData.end_date.tempEndDate = this.latestDate
        },
        clicked1v() {
            let sevenMmsDaysEarlier = dayjs(this.latestDate)
                .subtract(6, "day")
                .format("YYYY-MM-DD")
            this.fieldData.start_date.tempStartDate = sevenMmsDaysEarlier
            this.fieldData.end_date.tempEndDate = this.latestDate
        },
        clickedF1v() {
            let weekday = require("dayjs/plugin/weekday")
            dayjs.extend(weekday)
            let isAdToDateSunday = dayjs(this.latestDate).weekday() == 6
            let monday = dayjs(this.latestDate)
                .weekday(isAdToDateSunday ? 0 : -7)
                .format("YYYY-MM-DD")
            let sunday = dayjs(this.latestDate)
                .weekday(isAdToDateSunday ? 6 : -1)
                .format("YYYY-MM-DD")
            this.fieldData.start_date.tempStartDate = monday
            this.fieldData.end_date.tempEndDate = sunday
        },
        popupIsShown() {
            document.addEventListener("keyup", this.onKeyUp)
            const query = this.$store.getters.getChannelsQuery
            this.fieldData.start_date.tempStartDate = query.start_date
            this.fieldData.end_date.tempEndDate = query.end_date
            this.fieldData.grouptime.tempGroupTime = this.fieldData.grouptime.options.find(o => o.id === query.grouptime)
        },
        popupIsHidden() {
            document.removeEventListener("keyup", this.onKeyUp)
        },
        saveDate() {
            this.$store.commit(Mutations.setChannelsQuery, {
                ...this.$store.getters.getChannelsQuery,
                start_date: this.fieldData.start_date.tempStartDate,
                end_date: this.fieldData.end_date.tempEndDate,
                grouptime: this.fieldData.grouptime.tempGroupTime.id,
            })
        },
        onKeyUp(event) {
            if (event.key === "Enter" && this.fieldData.showDateModal) {
                this.fieldData.showDateModal = false
                this.saveDate()
            }
        },
    },
}
</script>
<style scoped>
>>> .modal-content {
    width: 33rem;
}
.error {
    color: red;
    transition: color 0.3s;
}
</style>
