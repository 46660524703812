export const baseFields = {
    date: {
        start_date: {
            label: "Konsumtionsperiod från",
            errorMsg: "",
            type: "start_date_channels",
            identifier: "start_date",
            default: null,
            required: true,
            datePeriod: {},
            tempStartDate: "",
            hasError: false,
        },
        end_date: {
            label: "Konsumtionsperiod till",
            type: "end_date_channels",
            errorMsg: "",
            identifier: "end_date",
            default: null,
            required: true,
            editable: true,
            datePeriod: {},
            tempEndDate: "",
            hasError: false,
            disabled: false,
        },
        grouptime: {
            label: "Visningsläge",
            identifier: "grouptime",
            type: "dropdown-grouptime-select",
            options: [
                { label: "Dag", id: "day" },
                { label: "7 dagar (rullande)", id: "sevendays" },
                { label: "Vecka", id: "week" },
            ],
            datePeriod: {},
            tempGroupTime: "",
        },
        showDateModal: false,
    },
    providers: {
        label: "Kanaler",
        type: "provider-picker-channels",
        identifier: "provider_keys",
        title: "Kanaler",
        sites: [],
        channels: [],
        default: [],
        showChannelModal: false,
    },
    target_group: {
        label: "Målgrupp",
        identifier: "target_group",
        type: "target_group_channels",
        config: {},
        showTargetGroupModal: false,
    },
}
